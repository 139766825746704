import anime from "animejs";
import vis from "vis";

//backgroundcolorを調べて,設定されている箇所がなければ,1番目rowに色を付ける
//backgroundcolorを調べて,設定されている箇所があれば,見つかったrow番号プラス1した箇所に色を付ける.他の箇所の色は消す.
const ChangeExecColor = (table: HTMLTableElement | null, code: string): number => {
  // tableのtr要素を取得
  const rowElems = table!.rows;
  const rowColor = "#ee82ee";
  let count = 0;
  console.log(rowElems.length);
  //tableの1番上はタイトルが表示されるため,ループ対象から除く
  for (let i = 1; i <= rowElems.length - 1; i++) {
    const style = getComputedStyle(rowElems[i]).backgroundColor;
    //    console.log("style" + style);

    //色が付いているtableを見つけたら,tableの色を消して次の行の色を付ける
    if (style === "rgb(238, 130, 238)") {
      count++;
      rowElems[i].style.backgroundColor = "";

      //codeがplusの時,lengthが一番最後の行だったら,何もしない
      //codeがminusの時,lengthが一番最初の行だったら,何もしない
      if ((i === rowElems.length - 1 && code === "plus") || (i === 1 && code === "minus")) {
        return 0;
      }

      if (code === "plus") {
        rowElems[i + 1].style.backgroundColor = rowColor;
        return i + 1;
      } else if (code === "minus") {
        rowElems[i - 1].style.backgroundColor = rowColor;
        return i - 1;
      }
    }
  }

  //backgroudColorが全て色が付いていなかったら,1番目の箇所に色を付ける
  if (count === 0 && code === "plus") {
    rowElems[1].style.backgroundColor = rowColor;
    return 1;
  } else if (count === 0 && code === "minus") {
    rowElems[rowElems.length - 1].style.backgroundColor = rowColor;
    return rowElems.length - 1;
  }
  return 0;
};

const moveIndicator = (table: HTMLTableElement | null, pos: number): void => {
  console.log(table?.clientWidth);
  console.log(table?.clientHeight);
  const arrow = document.getElementById("circle")!;
  arrow.style.left = table!.clientWidth + 3 + "px";
  arrow.style.bottom = table!.clientHeight + 3 + "px;";
  anime({
    targets: arrow,
    translateY: pos * 25,
  });
  //anime({
  //  targets: arrow,
  //  translateX: [{ value: 250, duration: 1000, delay: 500 }],
  //  scaleX: [
  //    { value: 5, duration: 100, delay: 500, easing: "easeOutExpo" },
  //    { value: 0, duration: 10 },
  //  ],
  //});
};

let nodes = null;
let edges = null;
let container = null;
let data = null;
let options = {};
//let network = null;

const makeNetwork = (table: HTMLTableElement | null, pos: number) => {
  if (pos === 1) {
    nodes = new vis.DataSet([
      { id: 1, label: String(table!.rows[pos].cells[2].textContent) },
      { id: 2, label: String(table!.rows[pos].cells[3].textContent) },
    ]);

    edges = new vis.DataSet([{ from: 1, to: 2, arrows: "to" }]);
    container = document.getElementById("network")!;
    data = {
      nodes: nodes,
      edges: edges,
    };
    options = {
      layout: {
        hierarchical: {
          direction: "LR",
          sortMethod: "directed",
          levelSeparation: 300,
        },
      },
      physics: {
        hierarchicalRepulsion: {
          nodeDistance: 140,
        },
      },
    };
    //    network = new vis.Network(container, data, options);
    //new vis.Network(container, data, options);
    const network = new vis.Network(container, data, options);

    network.on("click", function (params) {
      params.event = "[original event]";
      document.getElementById("eventSpan")!.innerHTML = "<h2>Click event:</h2>" + JSON.stringify(params, null, 4);
      console.log("click event, getNodeAt returns: " + network.getNodeAt(params.pointer.DOM));
    });
    network.on("doubleClick", function (params) {
      params.event = "[original event]";
      document.getElementById("eventSpan")!.innerHTML = "<h2>doubleClick event:</h2>" + JSON.stringify(params, null, 4);
    });
    network.on("oncontext", function (params) {
      params.event = "[original event]";
      document.getElementById("eventSpan")!.innerHTML = "<h2>oncontext (right click) event:</h2>" + JSON.stringify(params, null, 4);
    });
    network.on("dragStart", function (params) {
      // There's no point in displaying this event on screen, it gets immediately overwritten
      params.event = "[original event]";
      console.log("dragStart Event:", params);
      console.log("dragStart event, getNodeAt returns: " + network.getNodeAt(params.pointer.DOM));
    });
    network.on("dragging", function (params) {
      params.event = "[original event]";
      document.getElementById("eventSpan")!.innerHTML = "<h2>dragging event:</h2>" + JSON.stringify(params, null, 4);
    });
    network.on("dragEnd", function (params) {
      params.event = "[original event]";
      document.getElementById("eventSpan")!.innerHTML = "<h2>dragEnd event:</h2>" + JSON.stringify(params, null, 4);
      console.log("dragEnd Event:", params);
      console.log("dragEnd event, getNodeAt returns: " + network.getNodeAt(params.pointer.DOM));
    });
    network.on("controlNodeDragging", function (params) {
      params.event = "[original event]";
      document.getElementById("eventSpan")!.innerHTML = "<h2>control node dragging event:</h2>" + JSON.stringify(params, null, 4);
    });
    network.on("controlNodeDragEnd", function (params) {
      params.event = "[original event]";
      document.getElementById("eventSpan")!.innerHTML = "<h2>control node drag end event:</h2>" + JSON.stringify(params, null, 4);
      console.log("controlNodeDragEnd Event:", params);
    });
    network.on("zoom", function (params) {
      document.getElementById("eventSpan")!.innerHTML = "<h2>zoom event:</h2>" + JSON.stringify(params, null, 4);
    });
    network.on("showPopup", function (params) {
      document.getElementById("eventSpan")!.innerHTML = "<h2>showPopup event: </h2>" + JSON.stringify(params, null, 4);
    });
    network.on("hidePopup", function () {
      console.log("hidePopup Event");
    });
    network.on("select", function (params) {
      console.log("select Event:", params);
    });
    network.on("selectNode", function (params) {
      console.log("selectNode Event:", params);
    });
    network.on("selectEdge", function (params) {
      console.log("selectEdge Event:", params);
    });
    network.on("deselectNode", function (params) {
      console.log("deselectNode Event:", params);
    });
    network.on("deselectEdge", function (params) {
      console.log("deselectEdge Event:", params);
    });
    network.on("hoverNode", function (params) {
      console.log("hoverNode Event:", params);
    });
    network.on("hoverEdge", function (params) {
      console.log("hoverEdge Event:", params);
    });
    network.on("blurNode", function (params) {
      console.log("blurNode Event:", params);
    });
    network.on("blurEdge", function (params) {
      console.log("blurEdge Event:", params);
    });
  }
};
export { ChangeExecColor, moveIndicator, makeNetwork };
