import React from "react";
import { ChangeExecColor, makeNetwork, moveIndicator } from "./ts";
import "./css/App.css";
import "./css/circle.css";
import "./css/network.css";
import { Actions } from "./ts/actions";

const App = () => {
  const CheckProtocolColor = (protocol: any) => {
    switch (protocol.toLowerCase()) {
      case "protocol":
        return "white";
      case "ssdp":
        return "#ff7f7f";
      case "http":
        return "#ff7fbf";
      case "https":
        return "#ffff7f";
      case "arp":
        return "#7fffff";
      case "quic":
        return "#7fff7f";
      case "tcp":
        return "#bfff7f";
      case "dns":
        return "#bf7fff";
      case "tlsv1.2":
        return "#7fffbf";
      case "tlsv1.3":
        return "#ffbf7f";
      default:
        return "undefined";
    }
  };
  const actionsArray = [] as any;
  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    switch (files![0].type) {
      case "application/json":
        document.getElementById("result")!.innerText = "NG";
        return;
      case "text/csv":
        document.getElementById("result")!.innerText = "OK";
        break;
      default:
        document.getElementById("result")!.innerText = "NG";
        return;
    }
    //FileReaderの作成
    const reader: any = new FileReader();
    //テキスト形式で読み込む
    reader.readAsText(files![0]);

    //読込終了後の処理
    reader.onload = () => {
      const line = reader.result!.split(/\r\n|\n/);
      let body = document.getElementById("divTable");
      let tbl = document.createElement("table");
      let tblBody = document.createElement("tbody");
      for (let i = 0; i < line.length; i++) {
        let row = document.createElement("tr");

        const words = line[i].split(",");
        if (words.length === 1) {
          continue;
        }
        const seqNo = words[0].replace(/"/g, "");
        const time = words[1].replace(/"/g, "");
        const sourceAddr = words[2].replace(/"/g, "");
        const destAddr = words[3].replace(/"/g, "");
        const protocol = words[4].replace(/"/g, "");
        const length = words[5].replace(/"/g, "");
        //const info = words[6].replace(/"/g, "");

        //ここでactionsの設定を行う
        const actionList = actions.setActions(seqNo, time, sourceAddr, destAddr, protocol, length);
        actionsArray.push(actionList);

        let cell1 = document.createElement("td");
        let cellText1 = document.createTextNode(seqNo);
        cell1.appendChild(cellText1);
        row.appendChild(cell1);

        let cell2 = document.createElement("td");
        let cellText2 = document.createTextNode(time);
        cell2.appendChild(cellText2);
        row.appendChild(cell2);

        let cell3 = document.createElement("td");
        let cellText3 = document.createTextNode(sourceAddr);
        cell3.appendChild(cellText3);
        row.appendChild(cell3);

        let cell4 = document.createElement("td");
        let cellText4 = document.createTextNode(destAddr);
        cell4.appendChild(cellText4);
        row.appendChild(cell4);

        let cell5 = document.createElement("td");
        let cellText5 = document.createTextNode(protocol);
        let color = CheckProtocolColor(protocol);
        cell5.style.backgroundColor = color;
        cell5.appendChild(cellText5);
        row.appendChild(cell5);

        let cell6 = document.createElement("td");
        let cellText6 = document.createTextNode(length);
        cell6.appendChild(cellText6);
        row.appendChild(cell6);

        let cell7 = document.createElement("td");
        let cellText7 = document.createElement("div");

        cell7.appendChild(cellText7);
        row.appendChild(cell7);

        tblBody.appendChild(row);
      }
      tbl.appendChild(tblBody);
      body!.appendChild(tbl);
      tbl.setAttribute("border", "2");
      tbl.setAttribute("id", "table");
    };
  };

  const stepBackExec = () => {
    const table: HTMLTableElement | null = document.getElementById("table") as HTMLTableElement;
    let tableLineNum = ChangeExecColor(table, "minus");
    moveIndicator(table, tableLineNum);
    console.log("stepBackExec tableLineNum = " + tableLineNum);
    for (let i = 0; i < actionsArray[tableLineNum].length; i++) {
      actionsArray[tableLineNum][i]();
    }
  };

  const resetExec = () => {
    const table: HTMLTableElement | null = document.getElementById("table") as HTMLTableElement;
    ChangeExecColor(table, "reset");
    moveIndicator(table, 0);
  };

  const stepRunExec = () => {
    const table: HTMLTableElement | null = document.getElementById("table") as HTMLTableElement;

    let tableLineNum = ChangeExecColor(table, "plus");
    moveIndicator(table, tableLineNum);
    makeNetwork(table, tableLineNum);
    console.log("stepRunExec tableLineNum = " + tableLineNum);
    for (let i = 0; i < actionsArray[tableLineNum].length; i++) {
      actionsArray[tableLineNum][i]();
    }
  };

  const runExec = () => {
    const table: HTMLTableElement | null = document.getElementById("table") as HTMLTableElement;
    ChangeExecColor(table, "reset");
    moveIndicator(table, 0);
    for (let i = 0; i < actionsArray.length; i++) {
      const tableLineNum = ChangeExecColor(table, "plus");
      console.log("tableLineNum = " + tableLineNum);
      for (let j = 0; j < actionsArray[i].length; j++) {
        actionsArray[i][j]();
      }
    }
    moveIndicator(table, actionsArray.length);
  };
  const actions = new Actions();

  return (
    <div>
      <p>csvファイルを指定してください</p>
      <input type="file" id="file" onChange={onFileInputChange}></input>
      <div id="result">result</div>
      <div id="divTable">table</div>
      <div className="circle" id="circle"></div>
      <input type="button" value="step実行" onClick={stepRunExec}></input>
      <input type="button" value="1つ前に戻す" onClick={stepBackExec}></input>
      <input type="button" value="最初に戻す" onClick={resetExec}></input>
      <input type="button" value="すべてを実行" onClick={runExec}></input>
      <div id="network"></div>
      <pre id="eventSpan"></pre>
    </div>
  );
};

export default App;
