export class Actions {
  actionsList: Array<any> = [];
  actionsArray: Array<any> = [];
  constructor() {
    this.actionsList = [];
    this.actionsArray = [];
  }
  execActions(tableLineNum: number) {
    console.log(tableLineNum);
    for (let i = 0; i < this.actionsArray.length; i++) {
      this.actionsArray[tableLineNum][i]();
    }
  }
  setActions = (seqNo: string, time: string, sourceAddr: string, destAddr: string, protocol: string, length: number) => {
    for (let i = 0; i < 8; i++) {
      switch (i) {
        case 0:
          this.actionsList[0] = this.deleteEntity;
          break;
        case 1:
          this.actionsList[1] = this.checkEntity;
          break;
        case 2:
          this.actionsList[2] = this.showEntity;
          break;
        case 3:
          this.actionsList[3] = this.drawPath;
          break;
        case 4:
          this.actionsList[4] = this.deletePath;
          break;
        case 5:
          this.actionsList[5] = this.checkProtocolColor;
          break;
        case 6:
          this.actionsList[6] = this.setProtocolColor;
          break;
        case 7:
          this.actionsList[7] = this.movePacket;
          break;
        default:
          console.log("default");
          break;
      }
    }
    return this.actionsList;
  };

  deleteEntity() {
    //実体が表示されていたら消す,表示されていなければ何もしない
    console.log("deleteEntity");
  }
  checkEntity() {
    //実体が表示されているかチェックする
    console.log("checkEntity");
    console.log("checkSrcIPEntity");
    console.log("checkDestIPEntity");
  }
  showEntity() {
    //実体がなければ表示,あれば何もしない
    console.log("showEntity");
    console.log("showSrcIPEntity");
    console.log("showDestIPEntity");
  }
  drawPath() {
    //通信のpacketが通る道を描く,あれば何もしない
    console.log("drawPath");
  }
  deletePath() {
    //通信のpacketが通る道を消す,無ければ何もしない
    console.log("deletePath");
  }
  checkProtocolColor() {
    //プロトコルの色をチェックする
    console.log("checkProtocolColor");
  }
  setProtocolColor() {
    //プロトコルの色をセットする
    console.log("setProtocolColor");
  }
  movePacket() {
    //パケットをsrcからdestへ移動する
    console.log("movePacket");
  }
}
